<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="py-2">

      <!-- Register v1 -->
      <b-card class="mb-0 w-100">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <b-img
              :src="appLogoImage"
              style="max-height: 50px"
              alt="logo"
            />
          </div>
          <div>
            <div class="list-unstyled d-inline-flex">
              <locale />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="ml-1"
                size="sm"
                variant="danger"
                @click="$router.push({ name: 'auth-logout'})"
              ><feather-icon
                 icon="LogOutIcon"
                 class="mr-50"
               />
                <span class="align-middle">{{ $t('core.btn_logout') }}</span>
              </b-button>
            </div>
          </div>
        </div>

        <hr>
        <div class="text-left">
          <small>{{ $t('plan_select.step') }} <b>2/3</b></small>
        </div>

        <h2>
          {{ $t('plan_select.choose_a_plan_that_suits_you') }} 💰
        </h2>

        <div class="d-flex align-items-center justify-content-center">
          <h6 class="mr-1 mb-0">
            {{ $t('plan_select.monthly') }}
          </h6>
          <b-form-checkbox
            id="priceSwitch"
            v-model="status"
            name="price-switch"
            value="annually"
            unchecked-value="monthly"
            switch
            @input="tooglePlan"
          />
          <h6 class="ml-50 mb-0">
            {{ $t('plan_select.yearly') }}
          </h6>
        </div>
        <!-- pricing plan cards -->
        <b-row class="justify-content-center mt-25">
          <b-col
              v-for="plan in planList"
              :key="plan.locale"
              class="mx-25 rounded border border-dark px-0"
          >
            <b-card no-body>
              <h4 class="text-center pt-25 pb-25 bg-primary text-white">{{ plan.title }}</h4>
              <!-- annual plan -->
              <div class="text-center mt-1">
                <div v-show="plan.plan_tag !='enterprise'">
                  <h4>{{price_formetter(plan)}}</h4>
                </div>
                <div v-show="plan.plan_tag =='enterprise'">
                  <h4>-</h4>
                </div>
              </div>
              <!--/ annual plan -->
              <hr>
              <div v-b-tooltip.hover="$t('plan_select.hover_qr_code')" class="text-nowrap d-flex mx-1" style="font-size: 12px">✅ {{$t('plan_select.qr_code')}}: <b class="ml-25"><div v-if="plan.plan_tag !='enterprise'">{{ plan.qr_code_limit }}</div><div v-else>♾️</div></b></div><hr>
              <div v-b-tooltip.hover="$t('plan_select.hover_user')" class="text-nowrap d-flex mx-1" style="font-size: 12px">✅ {{$t('plan_select.user')}}: <b class="ml-25"><div v-if="plan.plan_tag !='enterprise'">{{ plan.total_user_limit }}</div><div v-else>♾️</div></b></div><hr>
              <div v-b-tooltip.hover="$t('plan_select.hover_group')" class="text-nowrap d-flex mx-1" style="font-size: 12px">✅ {{$t('plan_select.group')}}: <b class="ml-25"><div v-if="plan.plan_tag !='enterprise'">{{ plan.total_group_limit }}</div><div v-else>♾️</div></b></div><hr>
              <div v-b-tooltip.hover="$t('plan_select.hover_organization')" class="text-nowrap d-flex mx-1" style="font-size: 12px">✅ {{$t('plan_select.organization')}}: <b class="ml-25"><div v-if="plan.plan_tag !='enterprise'">{{ plan.total_organization_limit }}</div><div v-else>♾️</div></b></div><hr>
              <div v-b-tooltip.hover="$t('plan_select.hover_storage')" class="text-nowrap d-flex mx-1" style="font-size: 12px">✅ {{$t('plan_select.storage')}}: <b class="ml-25"><div v-if="plan.plan_tag !='enterprise'">{{ plan.max_disk_quota / (1024 ** 3) }} GB</div><div v-else>♾️</div></b></div><hr>
              <div v-b-tooltip.hover="$t('plan_select.hover_biolink')" class="text-nowrap d-flex mx-1" style="font-size: 12px"><div v-if="plan.biolink_status">✅</div><div v-else>❌</div>  <div class="ml-25">BioLink</div></div><hr>
              <div v-b-tooltip.hover="$t('plan_select.hover_return')" class="text-nowrap d-flex mx-1" style="font-size: 12px"><div v-if="plan.custom_return_url">✅</div><div v-else>❌</div>  <div class="ml-25">{{$t('plan_select.return')}}</div></div><hr>
              <div v-b-tooltip.hover="$t('plan_select.hover_custom_logo')" class="text-nowrap d-flex mx-1" style="font-size: 12px"><div v-if="plan.custom_logo">✅</div><div v-else>❌</div>  <div class="ml-25">{{$t('plan_select.custom_logo')}}</div></div><hr>
              <div v-b-tooltip.hover="$t('plan_select.hover_on_premise')" class="text-nowrap d-flex mx-1" style="font-size: 12px"><div v-if="plan.on_prime">✅</div><div v-else>❌</div>  <div class="ml-25">{{$t('plan_select.on_premise')}}</div></div><hr>
              <!-- buttons -->

              <b-button
                  v-show="plan.plan_tag ==='free'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  class="mt-25 mb-1  mx-1 text-nowrap"
                  variant="outline-primary"
                  @click="planSelectButton(plan)"
              >
                {{ $t('plan_select.login') }}
              </b-button>
              <b-button
                  v-show="plan.plan_tag ==='enterprise'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  class="mt-25 mb-1  mx-1 text-nowrap"
                  variant="outline-primary"
                  @click="planSelectButton(plan)"
              >
                {{ $t('plan_select.contact_us') }}
              </b-button>
              <b-button
                  v-show="plan.plan_tag !=='free' & plan.plan_tag !=='enterprise'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  class="mt-25 mb-1  mx-1 text-nowrap"
                  variant="outline-primary"
                  @click="planSelectButton(plan)"
              >
                {{ $t('plan_select.start_subscription') }}
              </b-button>
            </b-card>
          </b-col>
        </b-row>
        <!--/ pricing plan cards -->
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol, BBadge, BImg, BDropdownItem, BTooltip, VBTooltip,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'
import Ripple from 'vue-ripple-directive'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'
import AppCollapse from '@core/components/app-collapse/AppCollapse'
import useJwt from '@/auth/jwt/useJwt'
import { $themeConfig } from '@themeConfig'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BDropdownItem,
    // BSV
    BCard,
    BLink,
    BTooltip,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
    Locale,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
      status: 'annually',
      monthlyPlanShow: false,
      enterpriseContactUrl: process.env.VUE_APP_ENTERPRISE_CONTACT_URL,
      planList: [],
    }
  },
  computed: {
  },
  created() {
    this.getPlanData()
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  methods: {
    price_formetter(plan) {
      const currency = plan.currency.currency_name
      let formatter = null
      if (currency == 'TL') {
        formatter = new Intl.NumberFormat('tr-TR', {style: 'currency', currency: 'TRY', minimumFractionDigits: 0, maximumFractionDigits: 0,})
      } else if (currency == 'USD') {
        formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0,})
      } else if (currency == 'EUR') {
        formatter = new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0,})
      }
      if (this.monthlyPlanShow){
        return formatter.format(plan.monthly_price)
      } else {
        return formatter.format(plan.annual_price)
      }
    },
    planSelectButton(plan_name) {
      useJwt.clientregisterfreeplan({
        plan_period: this.status,
        plan_tag: plan_name.plan_tag,
      })
        .then(response => {
          if (plan_name.plan_tag === 'free') {
            const user_data = response.data.userData
            localStorage.setItem('userData', JSON.stringify(user_data))
            this.$ability.update(response.data.userData.ability)
            const routerPath = { name: 'dashboard' }
            this.$router.replace(routerPath)
          } else if (plan_name.plan_tag === 'enterprise') {
            window.open(this.enterpriseContactUrl, '_blank')
          } else if (plan_name.plan_tag !== 'enterprise') {
            const payload = {}
            payload.plan = plan_name
            payload.planPeriod = this.status
            localStorage.setItem('planData', JSON.stringify(payload))
            this.$router.push({ name: 'auth-register-creditcardpay' })
          }
        })
    },
    tooglePlan() {
      if (this.status === 'monthly') {
        this.monthlyPlanShow = true
      } else {
        this.monthlyPlanShow = false
      }
    },
    getPlanData() {
      useJwt.getPlanLocationListforClient({})
        .then(response => {
          this.planList = response.data
        })
        .catch(error => {
          console.log(error.response)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.error'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
